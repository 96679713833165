import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import showInfo from '../../../../../../modules/show-info'

const Wrapper = styled.div``
const ElectionP = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  display: flex;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  color: black;
`
const ElectionPBold = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  margin-top: unset;
  margin-left: 4px;
  margin-right: 4px;
  color: black;
`
const ColorP = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  text-align: center;
  color: dimgray;

  padding: 0.5em;
`
const ColorPWhite = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  text-align: center;
  color: white;

  padding: 0.5em;
`
const PercentBar = styled.div`
  display: flex;
`
const ColorFieldNoVotes = styled.div`
  height: 35px;
  background-color: lightgray;
  float: left;
  width: ${(props) => props.width};
`

const ColorField11 = styled.div`
  height: 35px;
  background-color: #c21c1c;

  width: ${(props) => props.width};
`
const Line = styled.hr`
  display: block;
  margin-top: 1.3em;
  margin-bottom: 1.3em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;

  color: rgb(245, 245, 245, 0.7);
  width: 100%;
`
const ColorField17 = styled.div`
  height: 35px;
  background-color: darkred;
  float: left;
  width: ${(props) => props.width};
`

const ColorField21 = styled.div`
  height: 35px;
  background-color: red;
  float: left;
  width: ${(props) => props.width};
`

const ElectionParticipationTexts = ({
  electionPermitted,
  time,
  percentage,
  amount,
  noVotes,
  emptyVotes,
  invalidVotes,
  ...props
}) => {
  if (!showInfo()) {
    percentage = '0%'
    noVotes = '100%'
  }

  return (
    <Wrapper {...props}>
      <PercentBar>
        <ColorField11 width={percentage}>
          <ColorPWhite>{showInfo() && percentage}</ColorPWhite>
        </ColorField11>

        <ColorFieldNoVotes width={noVotes}>
          <ColorP>{showInfo() ? noVotes : '0%'}</ColorP>
        </ColorFieldNoVotes>
      </PercentBar>
      <ElectionP>
        Wahlberechtigte: <ElectionPBold> {electionPermitted}</ElectionPBold>
      </ElectionP>
      {showInfo() && (
        <Fragment>
          <ElectionP>
            Wähler bis {time} Uhr {time == 15 ? ' Montag' : ''}: <ElectionPBold> {percentage}</ElectionPBold> - {amount}{' '}
            Stimmen
          </ElectionP>
          <ElectionP>
            Nicht-Wähler Anteil bis {time} Uhr {time == 15 ? ' Montag' : ''}: <ElectionPBold> {noVotes}</ElectionPBold>
          </ElectionP>
        </Fragment>
      )}
      {showInfo() && invalidVotes !== undefined && invalidVotes > 0 && (
        <div>
          <Line />
          <ElectionP>
            Gültige Stimmen: <ElectionPBold> {amount - invalidVotes}</ElectionPBold>
          </ElectionP>
          <ElectionP>
            Ungültige Stimmen: <ElectionPBold> {invalidVotes}</ElectionPBold>
          </ElectionP>
          <ElectionP>
            davon weiße Stimmen: <ElectionPBold> {emptyVotes}</ElectionPBold>
          </ElectionP>
        </div>
      )}
    </Wrapper>
  )
}
ElectionParticipationTexts.propTypes = {
  electionPermitted: PropTypes.string,
  election11: PropTypes.shape({
    percentage: PropTypes.string,
    amount: PropTypes.string,
    percentageDiff: PropTypes.string,
  }),
  election17: PropTypes.shape({
    percentage: PropTypes.string,
    amount: PropTypes.string,
    percentageDiff: PropTypes.string,
  }),
  election21: PropTypes.shape({
    percentage: PropTypes.string,
    amount: PropTypes.string,
    percentageDiff: PropTypes.string,
  }),
  noVotes: PropTypes.string,
}
ElectionParticipationTexts.defaultProps = {
  electionPermitted: 'Keine Daten vorhanden',
  time: '?',
  percentage: '0%',
  amount: '?',
  noVotes: '100%',
}
export default ElectionParticipationTexts
