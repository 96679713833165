import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CouncilChart from './components/CouncilChart'
import { Carousel } from '@dlab/react-materialize'
import Next from '../../../MayorElection/images/gw-next.svg'
import Back from '../../../MayorElection/images/gw-back.svg'

const Wrapper = styled.div`
  .carousel {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 300px;
    -webkit-perspective: 500px;
    perspective: 500px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    .carousel {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 300px;
      -webkit-perspective: 500px;
      perspective: 500px;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
  }
  .carousel.carousel-slider {
    top: 0;
    left: 0;
  }

  .carousel.carousel-slider .carousel-fixed-item {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 1;
  }

  .carousel.carousel-slider .carousel-fixed-item.with-indicators {
    bottom: 68px;
  }

  .carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .carousel.carousel-slider .carousel-item h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  .carousel.carousel-slider .carousel-item p {
    font-size: 15px;
  }

  .carousel .carousel-item {
    visibility: hidden;
    width: 350px;
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      width: 280px;
    }
    @media (max-width: ${320 + 'px'}) {
      width: 220px;
    }
    position: absolute;
    top: 0;
    left: 0;

    opacity: 1 !important;
  }

  .carousel .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: -24px;
    margin: 0;
    padding: unset;
    @media (max-width: ${320 + 'px'}) {
      bottom: -78px;
    }
  }

  .carousel .indicators .indicator-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 24px 4px;
    background-color: rgba(190, 190, 190, 0.5);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 50%;
  }

  .carousel .indicators .indicator-item.active {
    background-color: #333;
  }

  .carousel.scrolling .carousel-item .materialboxed,
  .carousel .carousel-item:not(.active) .materialboxed {
    pointer-events: none;
  }
`
const StyledCarousel = styled(Carousel)`
  padding: 1em;
`

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`
const NiceSlideButton = styled.img`
  display: none;
  max-height: 20px;
  width: 1rem;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
    position: absolute;
    z-index: 2;

    font-size: 50px;

    opacity: 0.8;
    bottom: 2.5em;
    right: -0.2em;
  }
`

const NiceSlideButtonBack = styled.img`
  display: none;
  max-height: 20px;
  width: 1rem;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
    position: absolute;
    z-index: 2;

    font-size: 50px;

    opacity: 0.8;
    bottom: 2.5em;
    left: -0.2em;
  }
`
const StyledChart = styled(CouncilChart)`
  padding: 10px;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(148, 148, 148, 0.5);
  -moz-box-shadow: 1px 1px 10px 0px rgba(148, 148, 148, 0.5);
  box-shadow: 1px 1px 10px 0px rgba(148, 148, 148, 0.5);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`
const CouncilList = ({ councilCharts, showNumbers, com, ...props }) => {
  let showSlider = false
  if (councilCharts.length > 1) {
    showSlider = true
  }
  return (
    <Wrapper {...props}>
      <FlexDiv>
        <StyledCarousel options={{ padding: 300, noWrap: true, indicators: true }}>
          {councilCharts &&
            councilCharts.length > 0 &&
            councilCharts.map((councilChart) => {
              return (
                <StyledChart
                  listName={councilChart.listName}
                  code={councilChart.code}
                  mayors={councilChart.mayors}
                  showNumbers={showNumbers}
                  com={com}
                ></StyledChart>
              )
            })}
        </StyledCarousel>
      </FlexDiv>
    </Wrapper>
  )
}

CouncilList.propTypes = {
  councilCharts: PropTypes.arrayOf(CouncilChart.propTypes),
}
CouncilList.defaultProps = {
  councilCharts: [
    {
      listName: 'Noch keine Auswertung vorhanden',
      code: '?',
      mayors: [
        {
          rank: '',
          name: '',
          votes: '',
        },
      ],
    },
    {
      listName: 'Noch keine Auswertung vorhanden',
      code: '?',
      mayors: [
        {
          rank: '',
          name: '',
          votes: '',
        },
      ],
    },
    {
      listName: 'Noch keine Auswertung vorhanden',
      code: '?',
      mayors: [
        {
          rank: '',
          name: '',
          votes: '',
        },
      ],
    },
  ],
}
export default CouncilList
