import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  text-align: center;
  font-size: 20px;
  background-color: white;
  font-weight: bold;

  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 20px;
  }
`

const InfoField = ({ ...props }) => (
  <Wrapper {...props}>
    <div>Die Live-Auszählung startet am Dienstag 09:00 Uhr</div>
  </Wrapper>
)
InfoField.propTypes = {}
InfoField.defaultProps = {}
export default InfoField
