import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import showInfo from '../../../../modules/show-info'

const SectionP = styled.p`
  font-family: ${(props) => props.theme.font.primary};
`

const ElectionPBold = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  margin-top: unset;
  margin-left: 4px;
  margin-right: 4px;
`
const Line = styled.hr`
  display: block;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  color: rgb(235, 235, 235, 1);
`
const Wrapper = styled.div``
const SectionsAndDate = ({ sections, sectionsMax, date, ...props }) => (
  <Wrapper {...props}>
    <SectionP>
      {showInfo() ? sections : '0'} von {sectionsMax} Sektionen ausgezählt, letzte Aktualisierung: {date} Uhr
    </SectionP>
  </Wrapper>
)
SectionsAndDate.propTypes = {
  sections: PropTypes.number,
  sectionsMax: PropTypes.number,
  date: PropTypes.string,
}
SectionsAndDate.defaultProps = {
  sections: 0,
  sectionsMax: 0,
  date: 'Keine Daten vorhanden',
}
export default SectionsAndDate
