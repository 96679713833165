import styled from 'styled-components'

const ContainerResults = styled.div`
  background-color: rgba(235, 235, 235, 1);
  margin-left: -${(props) => props.theme.sizes.gapEdge};
  margin-right: -${(props) => props.theme.sizes.gapEdge};
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-bottom: 27px;
`

export default ContainerResults
