const placeholder = 'https://s3-italy.brennercom.it/stol-images/img/speciale/gemeinderatswahlen/list-placeholder.svg'
const imageUrl = 'https://xenec.stol.it/media/fotos/gemeinderatswahlen2020/list/'

const ImageMapLists = (code) => {
  if (code === null) {
    return placeholder
  } else {
    return imageUrl + code + '.jpg'
  }
}
export default ImageMapLists
