import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ImageMapLists from '../../../../../Lists/components/CommunityDetail/images'
import Text from '../../../../../../../../../../components/Text'
import Scrollbars from '../../../../../../../../../../components/Scrollbars'
import ImageFallback from '../../../../../../../../../../components/ImageFallback'
import TruncateText from '../../../../../../../../../../components/TruncateText'
import showInfo from '../../../../../../../../modules/show-info'

const StyledScrollbar = styled(Scrollbars)``
const Wrapper = styled.div`
  background-color: white;

  height: 250px;
  max-height: 250px;
`

const IconContainer = styled.div`
  margin-right: 10px;
  width: 70px;
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const NameField = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  color: black;
  width: calc(100% - 90px);

  display: inline-block;
  margin: 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const StyledWrapper = styled.div`
  display: flex;
`

const Name = styled(Text)`
  font-family: ${(props) => props.theme.font.primary};
  color: inherit;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 13px;
  }

  @media (max-width: ${320 + 'px'}) {
    font-size: 12px;
  }

  ${(props) =>
    props.council &&
    props.showRed &&
    css`
      font-weight: bold;
      color: rgb(212, 29, 36);
    `}
`
const FlexDiv = styled.div`
  display: flex;
`

const Number = styled(Text)`
  font-family: ${(props) => props.theme.font.primary};
  margin-left: auto;
  margin-right: 3px;
`

const NumberDiv = styled.div`
  text-align: right;
  width: 2em;
  margin-left: -10px;
  margin-right: 2px;
`

const Line = styled.hr`
  display: block;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  color: rgb(235, 235, 235, 1);
`

const CouncilChart = ({ listName, code, mayors, showNumbers, com, ...props }) => {
  return (
    <Wrapper {...props}>
      <StyledScrollbar>
        <Header>
          <IconContainer>
            <ImageFallback src={ImageMapLists(code)} fallback={ImageMapLists(null)} />
          </IconContainer>
          <NameField>
            <TruncateText text={listName} />
          </NameField>
        </Header>
        <Line />
        {com.localeCompare('Bruneck') === 0 && listName.localeCompare('Südtiroler Volkspartei') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'GRIESSMAIR ROLAND'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}
        {com.localeCompare('Bruneck') === 0 && listName.localeCompare('Team K') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'GÖTSCH HUGO'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}
        {com.localeCompare('Bruneck') === 0 && listName.localeCompare('Il POLO - CIVICA per Brunico') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'BOVENZI ANTONIO'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}
        {com.localeCompare('Bruneck') === 0 && listName.localeCompare('VERDI-GRÜNE-VËRC') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'NIEDERKOFLER HANS PETER'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}

        {com.localeCompare('Brixen') === 0 && listName.localeCompare('Südtiroler Volkspartei') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'BRUNNER PETER'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}

        {com.localeCompare('Brixen') === 0 && listName.localeCompare('Team K') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'FINK INGO'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}

        {com.localeCompare('Brixen') === 0 &&
          listName.localeCompare('Grüne Bürgerliste Brixen - Alternativa Ecosociale Bressanone') === 0 && (
            <StyledWrapper>
              <Name showRed={true} council={true}>
                <FlexDiv>
                  <NumberDiv></NumberDiv> {'FREI MARKUS'}
                </FlexDiv>
              </Name>
            </StyledWrapper>
          )}

        {com.localeCompare('Brixen') === 0 &&
          listName.localeCompare('PD Partito Democratico  Bressanone Brixen') === 0 && (
            <StyledWrapper>
              <Name showRed={true} council={true}>
                <FlexDiv>
                  <NumberDiv></NumberDiv> {'PRADER RENATE'}
                </FlexDiv>
              </Name>
            </StyledWrapper>
          )}

        {com.localeCompare('Brixen') === 0 && listName.localeCompare('Die Freiheitlichen') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'GITZL EGON'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}
        {com.localeCompare('Leifers') === 0 && listName.localeCompare('LAIVES CIVICA LEIFERS') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'BERTOLINI LUCA'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}
        {com.localeCompare('Leifers') === 0 && listName.localeCompare('SVP') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'SEPPI GIOVANNI'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}
        {com.localeCompare('Leifers') === 0 && listName.localeCompare('UNITI PER LAIVES BIANCHI SINDACO') === 0 && (
          <StyledWrapper>
            <Name showRed={true} council={true}>
              <FlexDiv>
                <NumberDiv></NumberDiv> {'BIANCHI CHRISTIAN'}
              </FlexDiv>
            </Name>
          </StyledWrapper>
        )}
        {mayors.map((mayor) => (
          <StyledWrapper>
            <Name showRed={showInfo()} council={mayor.inCouncil}>
              <FlexDiv>
                <NumberDiv>{mayor.rank}.</NumberDiv> {mayor.name}
              </FlexDiv>
            </Name>

            {showInfo() && showNumbers && mayor.numPref !== undefined && <Number>{mayor.numPref}</Number>}
          </StyledWrapper>
        ))}
      </StyledScrollbar>
    </Wrapper>
  )
}
CouncilChart.propTypes = {
  listName: PropTypes.string,
  code: PropTypes.string,
  mayors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      votes: PropTypes.string,
    }),
  ),
}
CouncilChart.defaultProps = {
  listName: 'Keine Daten vorhanden',
  code: '?',
  com: '',
  mayors: [
    {
      rank: '?.',
      name: 'Keine Daten vorhanden',
      votes: '?',
    },
  ],
}
export default CouncilChart
