import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PercentageDetail from '../../../MayorElection/components/MayorDetail/components/PercentageDetail'
import ImageMapLists from './images'
import ImageFallback from '../../../../../../../../components/ImageFallback'
import TruncateText from '../../../../../../../../components/TruncateText'

const Wrapper = styled.div``

const IconContainer = styled.div`
  margin-right: 10px;
  width: 70px;
  height: 70px;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`

const FlexDiv = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`

const TextP = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  color: black;
  margin: 0;
`

const CommunityDetail = ({ listIcon, votesPercentage, code, seats, showNumbers, ...props }) => {
  return (
    <Wrapper {...props}>
      <FlexDiv>
        <IconContainer>
          <ImageFallback src={ImageMapLists(code)} fallback={ImageMapLists(null)} />
        </IconContainer>
        <TextP>
          <TruncateText text={listIcon} />
        </TextP>
      </FlexDiv>
      <PercentageDetail data={votesPercentage} seats={seats} showNumbers={showNumbers}></PercentageDetail>
    </Wrapper>
  )
}
CommunityDetail.propTypes = {
  listIcon: PropTypes.string,

  votesPercentage: PropTypes.shape({
    percentage: PropTypes.number,

    votes: PropTypes.string,
  }),
}
CommunityDetail.defaultProps = {
  listIcon: 'Keine Daten vorhanden',
  code: '?',
  votesPercentage: { percentage: 0, votes: '?' },
}
export default CommunityDetail
