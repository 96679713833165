import React from 'react'
import styled from 'styled-components'
import CouncilList from './components/CouncilList'
import SectionsAndDate from '../SectionsAndDate'
import Title from '../../../../../../components/Title'
import ContainerResults from '../ContainerResults'
import LineResults from '../LineResults'

const Wrapper = styled.div``

const StyledSections = styled(SectionsAndDate)`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`

const StyledTitle = styled(Title)`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    text-align: center;
  }
`

const Council = ({ council_in, sectionData, date, mayor, showMayor, com, ...props }) => {
  let showNumbers = false

  if (sectionData && sectionData.sections !== undefined && sectionData.sections > 0) {
    showNumbers = true
  }

  if (com && com.localeCompare('Mals') == 0) {
    council_in.forEach(function (i) {
      if (i.listName.localeCompare('Südtiroler Volkspartei') == 0) {
        i.mayors.forEach(function (j) {
          if (j.name.localeCompare('HUTTER KATHRIN') == 0) {
            j.numPref = 210
          }
        })
      }
    })
  }

  if (showMayor) {
    council_in.forEach(function (i) {
      if (i.code.localeCompare(mayor.listIcon) == 0) {
        i.mayors.sort(function compare(a, b) {
          if (a.name.localeCompare(mayor.name) === 0) {
            return -1000
          } else if (b.name.localeCompare(mayor.name) === 0) {
            return 1000
          }

          return a.numPref > b.numPref ? -1 : 1
        })
      }
    })
  }
  return (
    <Wrapper {...props}>
      <StyledTitle>Gemeinderat</StyledTitle>
      <LineResults />
      <StyledSections
        sectionsMax={sectionData && sectionData.sectionsMax}
        sections={sectionData && sectionData.sections}
        date={date}
      />
      <LineResults />

      {
        <ContainerResults>
          {council_in && council_in.length > 0 && (
            <CouncilList com={com} councilCharts={council_in} showNumbers={showNumbers}></CouncilList>
          )}
          {council_in && council_in.length == 0 && <CouncilList></CouncilList>}
        </ContainerResults>
      }
    </Wrapper>
  )
}
Council.propTypes = {
  council_in: CouncilList.propTypes,
  council_out: CouncilList.propTypes,
}
Council.defaultProps = {
  council_in: {
    listName: 'Keine Daten vorhanden',
    code: '?',
    mayors: [
      {
        rank: '?.',
        name: 'Keine Daten vorhanden',
        votes: '?',
      },
    ],
  },
  council_out: {
    councilCharts: [
      {
        listName: 'Keine Daten vorhanden',
        code: '?',
        mayors: [
          {
            rank: '?.',
            name: 'Keine Daten vorhanden',
            votes: '?',
          },
        ],
      },
    ],
  },
}
export default Council
