import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`

const ImageFallback = ({ src, fallback, ...props }) => {
  const [image, setImage] = useState(src)

  function imageError() {
    setImage(fallback)
  }

  return <StyledImage src={image} onError={imageError} {...props} />
}
ImageFallback.propTypes = {
  src: PropTypes.string,
  fallback: PropTypes.string,
}
export default ImageFallback
