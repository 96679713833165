import React from 'react'
import styled, { css } from 'styled-components'
import Title from '../../../../../../components/Title'
import ElectionParticipationTexts from './components/ElectionParticipationTexts'
import AdUnit from '../../../../../../components/AdUnit'
import round from 'lodash/round'

const Wrapper = styled.div``
const StyledElectionPart = styled(ElectionParticipationTexts)`
  width: 65%;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
  }
`

const StyledAdUnit = styled(AdUnit)`
  float: right;
  margin-left: auto;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-top: 1em;
  }
`
const StyledNotReached = styled.div`
  opacity: 1;
  font-size: 20px;
  color: #c21c1c;
  font-family: ${(props) => props.theme.font.primary};
`
const StyledTitle = styled(Title)`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    text-align: center;
  }
  ${(props) =>
    props.reached &&
    css`
      opacity: 0.5;
    `}
`
const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props.reached &&
    css`
      opacity: 0.5;
    `}
`

const ElectionParticipation = ({ electionData, reached, com, ...props }) => {
  let data = electionData[12]
  Object.keys(electionData).forEach(function (k) {
    if (!electionData[k].empty) {
      data = electionData[k]
    }
  })

  if (data.time == 115) {
    data.time = 15
  }
  if (data.amount > data.electionPermitted) {
    data.percentage = '100%'
    data.noVotes = '0%'
  }
  if (com && com.localeCompare('Lana') == 0) {
    data.electionPermitted = 9883
    data.percentage = round((data.amount / data.electionPermitted) * 100, 2)
    data.noVotes = 100 - data.percentage
    data.percentage += '%'
    data.noVotes += '%'
  }

  if (com && com.localeCompare('Latsch') == 0) {
    data.electionPermitted = 4322
    data.percentage = round((data.amount / data.electionPermitted) * 100, 2)
    data.noVotes = 100 - data.percentage
    data.percentage += '%'
    data.noVotes += '%'
  }
  return (
    <Wrapper {...props}>
      <StyledTitle reached={reached}>Wahlbeteiligung</StyledTitle>
      <FlexDiv reached={reached}>
        <StyledElectionPart
          electionPermitted={data.electionPermitted}
          amount={data.amount}
          time={data.time}
          percentage={data.percentage}
          noVotes={data.noVotes}
          emptyVotes={data.emptyVotes}
          invalidVotes={data.invalidVotes}
        />

        <StyledAdUnit adId={'S5'} />
      </FlexDiv>{' '}
    </Wrapper>
  )
}
ElectionParticipation.propTypes = {
  electionData: ElectionParticipationTexts.PropTypes,
}
ElectionParticipation.defaultProps = {
  electionData: ElectionParticipationTexts.defaultProps,
}
export default ElectionParticipation
