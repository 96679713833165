const placeholder = 'https://s3-italy.brennercom.it/stol-images/img/speciale/gemeinderatswahlen/mayor-placeholder.svg'
const imageUrl = 'https://xenec.stol.it/media/fotos/gemeinderatswahlen2020/mayor/'
const tempfixurl =
  'https://s3-italy.brennercom.it/stol-images/img/speciale/gemeinderatswahlen/andrian_1_profantergeorg.png'
const ImageMapMayor = (code) => {
  if (code === null) {
    return placeholder
  } else {
    if (code.localeCompare('andrian_1_profantergeorg') == 0) {
      return tempfixurl
    }
    return imageUrl + code + '.jpg?v=2'
  }
}
export default ImageMapMayor
