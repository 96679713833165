import styled from 'styled-components'

const LineResults = styled.hr`
  display: block;
  margin-top: 1.3em;
  margin-bottom: 1.3em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  color: rgb(245, 245, 245, 0.7);
`
export default LineResults
