import React from 'react'
import styled from 'styled-components'
import Title from '../../../../../../components/Title'
import CommunityDetail from './components/CommunityDetail'
import { Carousel } from '@dlab/react-materialize'
import SectionsAndDate from '../SectionsAndDate'
import ContainerResults from '../ContainerResults'
import LineResults from '../LineResults'
import NoDataField from '../NoDataField'

const SuperTitle = styled(Title)`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    text-align: center;
  }
`

const Wrapper = styled.div`
  .carousel {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 260px;
    -webkit-perspective: 500px;
    perspective: 500px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    .carousel {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 250px;
      -webkit-perspective: 500px;
      perspective: 500px;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
  }

  .carousel.carousel-slider {
    top: 0;
    left: 0;
  }

  .carousel.carousel-slider .carousel-fixed-item {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 1;
  }

  .carousel.carousel-slider .carousel-fixed-item.with-indicators {
    bottom: 68px;
  }

  .carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .carousel.carousel-slider .carousel-item h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  .carousel.carousel-slider .carousel-item p {
    font-size: 15px;
  }

  .carousel .carousel-item {
    visibility: hidden;
    width: 290px;
    @media (max-width: ${320 + 'px'}) {
      width: 250px;
    }
    height: 140px;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 1 !important;
  }

  .carousel .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: -6px;
    margin: 0;
    padding: unset;
    @media (max-width: ${600 + 'px'}) {
      bottom: -10px;
    }
    @media (max-width: ${320 + 'px'}) {
      bottom: -70px;
    }
  }

  .carousel .indicators .indicator-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 24px 4px;
    background-color: rgba(190, 190, 190, 0.5);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 50%;
  }

  .carousel .indicators .indicator-item.active {
    background-color: #333;
  }

  .carousel.scrolling .carousel-item .materialboxed,
  .carousel .carousel-item:not(.active) .materialboxed {
    pointer-events: none;
  }
`
const FlexDiv = styled(ContainerResults)`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-wrap: wrap;
  }
  position: relative;
`

const StyledCommunityDetail = styled(CommunityDetail)`
  width: 25%;
  padding: 10px;
  background-color: white;
  margin-right: 15px;
  padding: 10px;
  margin-top: 7px;
  margin-bottom: 15px;
  margin-left: auto;

  border: 1px solid rgb(220, 220, 220, 0.75);

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 40%;
    margin-left: unset;
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
    margin-left: unset;

    margin-right: auto;
  }

  border-radius: 5px;

  -webkit-box-shadow: 1px 1px 10px 0px rgba(148, 148, 148, 0.5);
  -moz-box-shadow: 1px 1px 10px 0px rgba(148, 148, 148, 0.5);
  box-shadow: 1px 1px 10px 0px rgba(148, 148, 148, 0.5);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`
const StyledSections = styled(SectionsAndDate)`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`

const NiceSlideButton = styled.img`
  display: none;
  max-height: 20px;
  width: 1rem;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
    position: absolute;
    z-index: 2;
    font-size: 50px;
    opacity: 0.8;
    bottom: 2.2em;
    right: -10px;
  }
`

const NiceSlideButtonBack = styled.img`
  display: none;
  max-height: 20px;
  width: 1rem;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
    position: absolute;
    z-index: 2;
    font-size: 50px;
    opacity: 0.8;
    bottom: 2.2em;
    left: -0.2em;
  }
`

const Lists = ({ com, data, sectionData, date, ...props }) => {
  let showNumbers = false

  if (sectionData.sections !== undefined && sectionData.sections > 0) {
    showNumbers = true
  }

  function goNext(carousel) {
    carousel.carousel('next')
  }
  let plusOne = false

  return (
    <Wrapper {...props}>
      <SuperTitle>Listen</SuperTitle>
      <LineResults />
      <StyledSections sectionsMax={sectionData.sectionsMax} sections={sectionData.sections} date={date} />
      <LineResults />
      <FlexDiv>
        {
          <Carousel id={'mycarousle'} options={{ padding: 300, noWrap: true, indicators: true }}>
            {data.data.map((list) => {
              plusOne = false
              if (
                com.localeCompare('Bruneck') == 0 &&
                (list.listIcon.localeCompare('Südtiroler Volkspartei') == 0 ||
                  list.listIcon.localeCompare('VERDI-GRÜNE-VËRC') == 0 ||
                  list.listIcon.localeCompare('Il POLO - CIVICA per Brunico') == 0 ||
                  list.listIcon.localeCompare('Team K') == 0)
              ) {
                plusOne = true
              }

              if (
                com.localeCompare('Brixen') == 0 &&
                (list.listIcon.localeCompare('Südtiroler Volkspartei') == 0 ||
                  list.listIcon.localeCompare('Grüne Bürgerliste Brixen - Alternativa Ecosociale Bressanone') == 0 ||
                  list.listIcon.localeCompare('PD Partito Democratico  Bressanone Brixen') == 0 ||
                  list.listIcon.localeCompare('Team K') == 0 ||
                  list.listIcon.localeCompare('Die Freiheitlichen') == 0)
              ) {
                plusOne = true
              }

              if (
                com.localeCompare('Leifers') == 0 &&
                (list.listIcon.localeCompare('UNITI PER LAIVES BIANCHI SINDACO') == 0 ||
                  list.listIcon.localeCompare('SVP') == 0 ||
                  list.listIcon.localeCompare('LAIVES CIVICA LEIFERS') == 0)
              ) {
                plusOne = true
              }
              return (
                <StyledCommunityDetail
                  listIcon={list.listIcon}
                  votesPercentage={list.votesPercentage}
                  code={list.code}
                  seats={plusOne ? list.seats + 1 : list.seats}
                  showNumbers={showNumbers}
                ></StyledCommunityDetail>
              )
            })}
          </Carousel>
        }
      </FlexDiv>
    </Wrapper>
  )
}
export default Lists
