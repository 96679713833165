import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Council from './components/Council'
import ElectionParticipation from './components/ElectionParticipation'
import Lists from './components/Lists'
import MayorElection from './components/MayorElection'
import SectionsAndDate from './components/SectionsAndDate'
import HeaderField from '../HeaderField'
import RowLocation from '../../../Weather/components/LiveData/components/RowLocation'
import AllCom from '../../../../config/data/southTyrolCommunities.json'
import { Redirect } from 'react-router-dom'
import ContainerFull from '../../../../components/ContainerFull'
import Link from '../../../../components/Link'
import InfoField from '../InfoField'
import showInfo from '../../modules/show-info'

const Wrapper = styled.div``
const StyledContainerFull = styled(ContainerFull).attrs(() => ({
  noMobileGapEdge: false,
}))`
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
`
const SuperTitle = styled.p`
  display: flex;
  font-size: 30px;
  font-family: ${(props) => props.theme.font.primary};
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(240, 240, 240, 1);
  font-weight: bold;
  margin: unset;
  padding-left: 5%;
  padding-right: 5%;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    text-align: center;
    margin-left: auto;
    display: block;
  }
`
const StyledCouncil = styled(Council)`
  ${(props) =>
    props.reached &&
    css`
      opacity: 0.5;
    `}
`
const StyledLists = styled(Lists)`
  ${(props) =>
    props.reached &&
    css`
      opacity: 0.5;
    `}
`
const StyledMayor = styled(MayorElection)`
  ${(props) =>
    props.reached &&
    css`
      opacity: 0.5;
    `}
`
const StyledElectionPart = styled(ElectionParticipation)`
  width: 100%;
  margin-bottom: 2em;
`
const StyledNotReached = styled.div`
  opacity: 1;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #c21c1c;
  font-family: ${(props) => props.theme.font.primary};
`
const CenterDiv = styled.div`
  text-align: center;
  margin-bottom: 20px;
`
const StyledLink = styled(Link)`
  cursor: pointer;
  text-align: center;
`
const Line = styled.hr`
  display: block;
  margin-top: 1.3em;
  margin-bottom: 1.3em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 60%;
  color: rgb(245, 245, 245, 0.7);
`
const StyledRowLocation = styled(RowLocation)`
  width: 300px;
  border-radius: 5px;
  margin-left: auto;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: auto;
    margin-top: 20px;
  }
`

const showInfo2 = () => {
  let currentTime = new Date().getTime()
  return currentTime >= 1600758000000
}

const ElectionResults = ({ data, location, ...props }) => {
  const [redirect, setRedirect] = useState(false)
  const options = []
  AllCom.all.forEach(function (com) {
    let option = { value: parseInt(com.istat.substring(2, 5)), label: com.name }
    if (
      option.value != 59 &&
      option.value != 16 &&
      option.value != 86 &&
      option.label.localeCompare(data.community) != 0
    ) {
      options.push(option)
    }
  })
  function onUpdateCommunity(selectedStation) {
    setRedirect({ pathname: '/p/gemeinderatswahlen-einegemeinde', search: `?gemeinde=${selectedStation.value}` })
  }

  if (redirect) return <Redirect to={redirect} />

  const quorum =
    data.electionData['115'].amount > 0
      ? (data.electionData['115'].amount / data.electionData['115'].electionPermitted) * 100
      : 100
  const quorumReached = quorum >= 50
  const electedMayor = data.mayorElection.data[0]
  let showMayor = false
  if (data.mayorElection.sections.sections == data.mayorElection.sections.sectionsMax) {
    showMayor = true
  }

  return (
    <Wrapper {...props}>
      <HeaderField location={location} />
      <SuperTitle>
        Wahlergebnisse in {data.community}
        <StyledRowLocation values={options} placeholder={'select_communities'} onChange={onUpdateCommunity} />
      </SuperTitle>
      {!showInfo2() && quorumReached && <InfoField />}
      {!quorumReached && (
        <StyledNotReached>
          Wahlbeteiligung zu niedrig; Quorum wurde nicht erreicht. Es braucht eine Neuwahl.
        </StyledNotReached>
      )}

      <StyledContainerFull>
        <StyledElectionPart reached={!quorumReached} com={data.community} electionData={data.electionData} />

        <StyledMayor
          reached={!quorumReached}
          sectionData={data.mayorElection.sections}
          date={data.date}
          data={data.mayorElection}
        />
        <StyledLists
          com={data.community}
          reached={!quorumReached}
          sectionData={data.lists.sections}
          date={data.date}
          data={data.lists}
        />
        <StyledCouncil
          reached={!quorumReached}
          mayor={electedMayor}
          showMayor={showMayor}
          sectionData={data.council.sections}
          date={data.date}
          council_in={data.council.data}
          com={data.community}
        />
      </StyledContainerFull>
      <CenterDiv>
        <StyledLink
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        >
          Zurück zum Anfang der Seite
        </StyledLink>
      </CenterDiv>
    </Wrapper>
  )
}
ElectionResults.propTypes = {
  data: PropTypes.shape({
    community: PropTypes.string,
    sectionsDate: SectionsAndDate.propTypes,
    electionData: ElectionParticipation.propTypes,
    mayorElection: MayorElection.propTypes,
    lists: Lists.propTypes,
    council: Council.propTypes,
  }),
}
ElectionResults.defaultProps = {
  data: {
    community: '?',
    sectionsDate: SectionsAndDate.defaultProps,
    electionData: ElectionParticipation.defaultProps,
    mayorElection: MayorElection.defaultProps,
    lists: Lists.defaultProps,
    council: Council.defaultProps,
  },
}
export default ElectionResults
export { Line as ElectionResults0Line }
