import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../../../../../components/Text'
import LinearProgress from '@mui/material/LinearProgress'
import showInfo from '../../../../../../../../modules/show-info'

const Wrapper = styled.div`
  width: 100%;
`

const Percent = styled(Text)`
  display: inline-flex;
  margin-right: auto;
`

const PercentageDetail = ({ data, seats, showNumbers, ...props }) => {
  return (
    <Wrapper {...props}>
      {showInfo() && showNumbers && (
        <Percent>
          <b>{data.percentage}% </b> - {data.votes} Stimmen {seats > 0 && <b>- {seats} Sitz(e) </b>}
        </Percent>
      )}
      <LinearProgress variant="determinate" value={showInfo() ? data.percentage : '0%'} color="secondary" />
    </Wrapper>
  )
}
PercentageDetail.propTypes = {
  data: {
    percentage: PropTypes.number,
    votes: PropTypes.number,
  },
}
PercentageDetail.defaultProps = {
  data: {
    percentage: 0,
    votes: '?',
  },
}
export default PercentageDetail
