import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PercentageDetail from './components/PercentageDetail'

import ImageMapMayor from '../../../../../AllMayors/components/AllCommunities/components/MayorChart/components/Mayor/images'
import ImageMapLists from '../../../Lists/components/CommunityDetail/images'
import ImageFallback from '../../../../../../../../components/ImageFallback'

const Wrapper = styled.div``

const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 10px;
`

const IconContainer = styled.div`
  width: 35px;
  height: 35px;
`

const ListDiv = styled.div`
  width: calc(100% - 80px);
`

const FlexDiv = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const TextP = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  margin: unset;
  margin-top: 5px;
  margin-bottom: 5px;
  color: black;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const MayorDetail = ({ name, listIcon, code, votesPercentage, showNumbers, ...props }) => {
  return (
    <Wrapper {...props}>
      <FlexDiv>
        <ImageContainer>
          <ImageFallback src={ImageMapMayor(code)} fallback={ImageMapMayor(null)} />
        </ImageContainer>

        <ListDiv>
          <TextP>{name}</TextP>
          <IconContainer>
            <ImageFallback src={ImageMapLists(listIcon)} fallback={ImageMapLists(null)} />
          </IconContainer>
        </ListDiv>
      </FlexDiv>
      <PercentageDetail data={votesPercentage} showNumbers={showNumbers}></PercentageDetail>
    </Wrapper>
  )
}
MayorDetail.propTypes = {
  name: PropTypes.string,
  listIcon: PropTypes.string,
  code: PropTypes.string,

  votesPercentage: PropTypes.shape({
    percentage: PropTypes.number,

    votes: PropTypes.string,
  }),
}
MayorDetail.defaultProps = {
  name: 'Keine Daten vorhanden',
  code: '?',
  listIcon: '?',
  votesPercentage: { percentage: 0, votes: '?' },
}
export default MayorDetail
